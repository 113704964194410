import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

// Utility function to decode the state parameter
const decodeStateParameter = (encodedState) => {
  try {
    console.log('Attempting to decode state:', encodedState);
    
    // First decode the URL-safe base64
    const base64Decoded = atob(encodedState);
    console.log('Base64 decoded:', base64Decoded);
    
    // Split the decoded string by the delimiter
    const [userId, encodedToken] = base64Decoded.split('$:$');
    console.log('Extracted userId:', userId);
    console.log('Extracted token:', encodedToken);
    
    if (!userId || !encodedToken) {
      throw new Error('Invalid state format');
    }

    return {
      userId,
      refreshToken: encodedToken
    };
  } catch (error) {
    console.error('Error in decodeStateParameter:', error);
    throw error;
  }
};

const OAuthReturn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState('');
  
  useEffect(() => {
    const handleOAuthReturn = async () => {
      console.log('OAuth return handler started');
      console.log('Current location:', location);
      
      try {
        // Get the state parameter from the URL
        const params = new URLSearchParams(location.search);
        const state = params.get('state');
        console.log('State from URL:', state);
        
        if (!state) {
          throw new Error('No state parameter found');
        }
        
        // Verify the state parameter against stored state
        const storedState = sessionStorage.getItem('googleOAuthState');
        console.log('Stored state:', storedState);
        
        if (!storedState) {
          throw new Error('No stored state found. Please try logging in again.');
        }
        
        // Clean up the stored state
        sessionStorage.removeItem('googleOAuthState');
        console.log('Stored state cleaned up');
        
        // Decode the state parameter
        const { userId, refreshToken } = decodeStateParameter(state);
        console.log('Successfully decoded state');
        
        // Store the authentication data
        localStorage.setItem('userId', userId);
        localStorage.setItem('refreshToken', refreshToken);
        console.log('Authentication data stored');
        
        // Redirect to the dashboard
        console.log('Attempting to navigate to dashboard');
        navigate('/dashboard', { replace: true });
      } catch (error) {
        console.error('OAuth return handling error:', error);
        setError(error.message || 'Authentication failed. Please try again.');
        
        // Redirect to login page after a delay if there's an error
        setTimeout(() => {
          console.log('Redirecting to login due to error');
          navigate('/login', { replace: true });
        }, 3000);
      }
    };
    
    handleOAuthReturn();
  }, [navigate, location]);
  
  console.log('Rendering OAuthReturn, error state:', error);
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {error ? (
        <div className="text-red-600 text-center p-4">
          <p className="mb-2">{error}</p>
          <p className="text-sm">Redirecting to login page...</p>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-4">
          <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
          <p>Completing sign in...</p>
        </div>
      )}
    </div>
  );
};

export default OAuthReturn;
