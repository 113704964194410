import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthContext';
import 'react-toastify/dist/ReactToastify.css';

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState('');
  const verificationAttempted = useRef(false);
  const { setAuthData } = useAuth();

  useEffect(() => {
    const verifyEmail = async () => {
      if (verificationAttempted.current) {
        return;
      }
      
      const token = searchParams.get('token');
      
      if (!token) {
        setError('Invalid verification link');
        setIsVerifying(false);
        return;
      }

      try {
        verificationAttempted.current = true;
        const response = await fetch('https://app.spiralreports.com/api/users/confirm-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        const data = await response.json();
        console.log('Verification response:', data);

        if (data.statusCode === 200) {
          // Update auth context with the user's data
          const userData = {
            access_token: localStorage.getItem('access_token'),
            userId: localStorage.getItem('userId'),
            refreshToken: localStorage.getItem('refreshToken')
          };

          // Set auth data in context
          setAuthData(userData);
          
          // Show success message
          toast.success('Email verified successfully!', {
            position: "bottom-right",
            autoClose: 3000,
          });

          setIsVerifying(false);
          
          // Navigate to dashboard
          navigate('/dashboard', { replace: true });
        } else {
          throw new Error(data.message || 'Email verification failed');
        }

      } catch (err) {
        console.error('Verification error:', err);
        setError(err.message);
        setIsVerifying(false);
        toast.error('Verification failed. Please try again.', {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    };

    verifyEmail();
  }, [navigate, searchParams, setAuthData]);

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow-md">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-2">
              Email Verification
            </h2>
            {isVerifying ? (
              <div className="space-y-4">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"/>
                <p className="text-gray-600">Verifying your email...</p>
              </div>
            ) : error ? (
              <div className="text-red-600">
                <p>{error}</p>
                <button
                  onClick={() => navigate('/login')}
                  className="mt-4 text-blue-600 hover:text-blue-800"
                >
                  Return to login
                </button>
              </div>
            ) : (
              <div className="text-green-600">
                <p>Email verified successfully!</p>
                <p className="text-sm mt-2">Redirecting to dashboard...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmailPage;